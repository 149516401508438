import './index.css'
import React, { Component } from 'react'
import { NavBar, Icon, SearchBar, List, Toast, Modal, Radio, Button, InputItem, Picker } from 'antd-mobile';
import { WY_URL, YL_URL, PAGE_URL ,BASE_URL, FORMAT, Axios} from '../../../utils/url'
import Wxzf from '../../../assets/images/wxzf.png'
import Zfbzf from '../../../assets/images/zfbzf.png'
const Item = List.Item;
const Brief = Item.Brief;
const RadioItem = Radio.RadioItem;
export default class Ewmjf extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        if(window.location.href.indexOf("&sjs")!=-1){
            console.log(111);
        }else{
            window.location.href = window.location.href+"&sjs="+Math.random();
            console.log(222);
        }
        const openid = this.props.location.search;
        console.log(this.props.location.search);

        // const openid = this.props.location.search;
        console.log(window.location.href);
    var ckh =1;
    if(openid.length>0){
      var open = openid.split("&");
        var openidArr = open[0].split("=");
        ckh = openidArr[1];
        console.log(ckh);
    }
        this.state = {
            ckh:ckh,
            jfdjList: [],
            je: "",
            isLoaded: false,
            isLoad: false,
            mxList: [],
            modal: false,
            modal1: false,
            modalfwxz: false,
            modaljfxx: false,
            value: "",
            orderid: "",
            isMxCk: "",
            yhid: "",
            fwxxbdlist: [],
            jtzz: "",
            jtzhlist1: [],
            jtzhlist2: [],
            jtzhlist3: [],
        }
    }
    componentDidMount = () => {

        this.forceUpdate();

        if (this.isWeiXin()) {
            this.setState({
                value: "WXPay.jsPay"
            });
        } else {
            this.setState({
                value: "trade.precreate"
            });
        }
        this.getList(this.state.ckh);
    }

    async getList(e) {
        var params = new URLSearchParams();
        const url = BASE_URL + 'ewmController/getdjfDdxx'
        params.append("ckh", e);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res);
            if(res.data.flag){
                this.setState({
                    fwxxbdlist: res.data.data,
                    isLoad: true
                });

            }else{
                Toast.info("暂无待缴费信息")
            }

        })
    }
    setMapList = () => {
        if (!this.state.isLoad) {
            return null;
        } else {
            return this.state.fwxxbdlist.map((item, key) => (
                <Item extra={item.je+"元"} align="middle"
                 thumb="https://zos.alipayobjects.com/rmsportal/dNuvNrtqUztHCwM.png"
                 multipleLine key={key} onClick={() => this.handlemx(item)}>
                     {item.dqzt} <Brief>交易类型：{item.mername}</Brief><Brief>备注：{item.bz}</Brief>
                </Item>
            ))
        }
    }
    handlemx = (item) =>{
        console.log(item);
        if(item.djid.indexOf("TLM_KERL_TZ_") != -1){
            item.djid= item.djid.substring(12);
            console.log(item.djid);
        }
        if(item.ywbm =="WYFJF"){
            this.setState({
                modal1: true,
                je:item.je,
                orderid:item.lcid,
                djid:item.djid,
                ywbm:item.ywbm,
                ywmc:item.mername,
                yhid:item.yhid,
                yhxm:item.sfrxm
            }, () => {
                this.setOrder();
            });
        }else if(item.ywbm =="CLJF"){
            var params = new URLSearchParams();
        const url = BASE_URL + 'ewmController/getCph'
        params.append("djid", item.djid);
        Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res);
            if(res.data.flag){
                this.setState({
                    cph: res.data.data[0].cph,
                    // je:res.data.data[0].je,
                    isLoad: true
                });
            }

        })
        this.setState({
            modal1: true,
            je:item.je,
            orderid:item.djid,
            ywbm:item.ywbm,
            ywmc:item.mername,
            yhid:item.yhid,
            yhxm:item.sfrxm,
            bz:item.bz
        }, () => {
            this.setOrder();
        });
    }else{
            this.setState({
                modal1: true,
                je:item.je,
                orderid:item.djid,
                ywbm:item.ywbm,
                ywmc:item.mername,
                yhid:item.yhid,
                yhxm:item.sfrxm
            }, () => {
                this.setOrder();
            });
        }

    }
    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }
    //判断是否是微信浏览器的函数
    isWeiXin() {
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        var ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    }


    onChange = (value, label) => {
        this.setState({
            value,
        });
    };
    setOrder = () => {
        console.log(this.state.ywmc);
        // if (this.state.czje === '') {
        //     Toast.info("请输入充值金额", 1, null, false);
        // } else
        // const userInfo = JSON.parse(localStorage.getItem("userInfo"));


            // const url = YL_URL + 'xiadanController/order';
            var url = "";
            if (this.isWeiXin()) {
                url = YL_URL + 'xiadanWXController/testorder'

            } else {
                url = YL_URL + 'xiadanController/testorder';

            }

            var params = new URLSearchParams();

            params.append('totalAmount', this.state.je);
            if(this.state.ywbm=="WYFJF"){
                params.append('goodsId', this.state.djid);
            }else{
                params.append('goodsId', this.state.orderid);
            }

            params.append('goodsName', this.state.ywmc);
            if(this.state.ywbm =="CLJF"){
                params.append('id', this.state.bz);

            }else{
                params.append('id', this.state.orderid);

            }

            params.append('price', FORMAT.number_format(this.state.je, 2));
            params.append('payMethod', this.state.value);
            params.append('ywbm', this.state.ywbm);
            params.append('yhid', this.state.yhid);
            params.append('yhxm', this.state.yhxm);
            params.append('bs',this.state.ywbm);
            params.append('ewmbs',"ewm");
            Toast.loading("加载中...", 0, null, true);
            Axios({
                method: 'post',
                url: url,
                data: params
            }).then((res) => {
                Toast.hide();
                if (this.state.value == "WXPay.jsPay") {
                    if (res.status == '200') {
                        window.parent.frames.location.href = res.data.data;
                    } else {
                        alert("支付失败，请重新支付");
                    }
                } else {
                    if (res.status == '200') {
                        let jsonData = JSON.parse(res.data.data);
                        if (jsonData.errCode == 'SUCCESS' && jsonData.xdmsg == "下单成功!") {
                            let payJson = JSON.parse(jsonData.respStr);
                            this.setState((state, props) => {
                                return {
                                    zfdz: payJson.appPayRequest.qrCode,
                                    merOrderId: payJson.merOrderId
                                }
                            },
                                () => {
                                    window.open(this.state.zfdz);
                                    this.setState({
                                        isPay: true
                                    });
                                    //支付
                                    // Modal.alert('确认支付？', '确认支付？', [
                                    //     { text: '取消', onPress: () => console.log('下次一定') },
                                    //     {
                                    //         text: '确定',
                                    //         onPress: () =>
                                    //             new Promise((resolve) => {

                                                    //跳转到登录
                                                    // window.open(this.state.zfdz);
                                                    // this.props.history.push({ pathname: '/ewmjfqr', query: { merOrderId: this.state.merOrderId } });
                                                    // setTimeout(resolve, 500);
                                    //             }),
                                    //     },
                                    // ])
                                }
                            )

                        } else {
                            alert("支付失败，请重新支付");
                        }
                    }
                }
            });


    }
    showModal = key => (e) => {
        if (this.state.sftzText == "否" && key == "modal1") {
            return;
        }
        this.setState({
            [key]: true,
        });
    }

    render() {
        const { value } = this.state;
        const data = [
            //{ value: "WXPay.jsPay", label: '微信支付', img: Wxzf },
            //{ value: "trade.precreate", label: '支付宝支付', img: Zfbzf },
        ];
        if (this.isWeiXin()) {
            var obj = {};
            obj.value = "WXPay.jsPay";
            obj.label = "微信支付";
            obj.img = Wxzf;
            data.push(obj);
            var obj1 = {};
            obj1.value = "trade.precreate";
            obj1.label = "支付宝支付";
            obj1.img = Zfbzf;
            // data.push(obj1);
        } else {
            var obj = {};
            obj.value = "trade.precreate";
            obj.label = "支付宝支付";
            obj.img = Zfbzf;
            data.push(obj);
        }
        return (
            <div style={{ marginTop: ".89rem" }}>
                {/* 导航栏#0F84D6 */}
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}

                >二维码缴费</NavBar>
                <div className="kf">
                    <List renderHeader={() => ''} >
                        {this.setMapList()}
                    </List>
                </div>
                <Modal
                    style={{ height: "60%", overflow: "auto" }}
                    // title="缴费信息"
                    popup
                    // closable={true}
                    visible={this.state.modaljfxx}
                    onClose={this.onClose('modaljfxx')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                </Modal>
                <Modal
                    style={{ height: "60%", overflow: "auto" }}
                    title="缴费明细"
                    popup
                    closable={true}
                    visible={this.state.modal}
                    onClose={this.onClose('modal')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                </Modal>
                {/* <Modal
                    popup
                    visible={this.state.modal1}
                    onClose={this.onClose('modal1')}
                    animationType="slide-up"
                    afterClose={() => { console.log('afterClose'); }}
                >
                    <List renderHeader={() => '缴费金额'}>
                        <InputItem
                            type='number'
                            placeholder="请输入缴费金额"
                            value={FORMAT.number_format(this.state.je/100, 2)}
                            editable={false}
                            extra="元"
                        >缴费金额</InputItem>
                    </List>
                    <List renderHeader={() => '选择支付方式'}>
                        {data.map(i => (
                            <RadioItem key={i.value} checked={value === i.value} onChange={() => this.onChange(i.value, i.label)}>
                                <img src={i.img} />  {i.label}
                            </RadioItem>
                        ))}
                        <Button type="primary" onClick={this.setOrder}>确认</Button>
                    </List>
                </Modal> */}

            </div>
        )
    }
}
