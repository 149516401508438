import './clxs.css'
import React, { Component } from 'react'
import { BASE_URL, Axios } from '../../../utils/url'
import { NavBar, Icon, List, Toast, ActionSheet, Modal ,NoticeBar} from 'antd-mobile'
const Item = List.Item;
const Brief = Item.Brief;
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
let wrapProps;
if (isIPhone) {
  wrapProps = {
    onTouchStart: e => e.preventDefault(),
  };
}
export default class Clxs extends Component {
  constructor(props) {
    super(props);
    localStorage.removeItem('clxx')
    this.state = {
      cllist: [],
      isLoaded: false,
      yhid: "",
      yhxm: "",
    }
  }
  componentDidMount = (e) => {
    if (e) {
      Toast.info("删除成功！")
    }
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.setState({
      yhxm: userInfo.ryxm,
      yhid: userInfo.sfzh
    })
    console.log(userInfo.sfzh)
    this.getClglList(userInfo.sfzh);

  }

  //获取列表
  async getClglList(sfzh) {
    const url = BASE_URL + 'bclcrsqbController/getClList';
    var params = new URLSearchParams();
    params.append("sfzh", sfzh);
    params.append("hhid", localStorage.getItem("bshtoken"));
    //获取人员身份证号并传递参数
    Toast.loading("加载中...", 0, null, true);
    await Axios({
      method: 'post',
      url: url,
      data: params
    }).then((res) => {
      Toast.hide();
      if (res.status === 200) {
        console.log(res);
        var list = [];

        if (res.data) {
          if (res.data.length > 0) {
            for (var i = 0; i < res.data.length; i++) {
              // if(res.data[i].yxdqzt ==="已发放"){
              list.push(res.data[i])
              // }
            }
          }
          this.setState({
            cllist: list,
            isLoaded: true
          });
          if (list.length === 0) {
            Toast.info("暂未查到需要续时的车辆")
          }
        } else {
          Toast.info("暂未查到需要续时的车辆")
        }
      }
    });
  }
  handleJump = (item) => {
    this.showActionSheet(item)
  }
  showActionSheet = (item) => {
    console.log(item)
    if (item.yxdqzt === '已发放') {
      const BUTTONS = ['续时', '查看详情', '取消'];
      ActionSheet.showActionSheetWithOptions({
        options: BUTTONS,
        cancelButtonIndex: BUTTONS.length - 1,
        destructiveButtonIndex: BUTTONS.length - 3,
        // title: 'title',
        message: '请选择您的操作',
        maskClosable: true,
        'data-seed': 'logId',
        wrapProps,
      },
        (buttonIndex) => {
          console.log(buttonIndex);
          if (buttonIndex === 0) {
            if(item.flag == 'Y'){
              Toast.fail('您的车辆因违停已被加入黑名单，具体业务咨询请拨打2172904', 3, null, false);
            }else{
              if (item.zzyxrq) {
                var startdate = new Date();
                var enddate = item.zzyxrq;
                console.log(enddate)
                //当前时间
                var sDate = new Date(startdate).getTime();

                console.log(sDate)
                // console.log(stime);
                //到期时间
                // var eDate = new Date(enddate).getTime();
                var eDate=new Date(enddate.replace(/-/g, "/")).getTime();
                console.log(eDate)
                var thisMothDays = 1000 * 3600 * 24 * 60;


               var xsDate = eDate - sDate;
              //  xsDate = parseInt(xsDate)

                if (eDate - sDate > 0) {
                  if (xsDate > thisMothDays) {
                    Toast.fail('请您于到期时间两个月内提交', 3, null, false);
                    return;
                  }else{
                    this.props.history.push({ pathname: "./clxsEitder", query: { clxx: item } });
                  }

                }else if(eDate < sDate){
                  this.props.history.push({ pathname: "./clxsEitder", query: { clxx: item } });
                }
              }
            }
          } else if (buttonIndex === 1) {
            this.props.history.push({ pathname: "./clxxOrLc", query: { clxx: item } });
          }
        });
    } else {
      const BUTTONS = ['查看详情', '取消'];
      ActionSheet.showActionSheetWithOptions({
        options: BUTTONS,
        cancelButtonIndex: BUTTONS.length - 1,
        destructiveButtonIndex: BUTTONS.length - 3,
        // title: 'title',
        message: '请选择您的操作',
        maskClosable: true,
        'data-seed': 'logId',
        wrapProps,
      },
        (buttonIndex) => {
          console.log(buttonIndex);
          if (buttonIndex === 0) {
            this.props.history.push({ pathname: "./clxxOrLc", query: { clxx: item } });
          }
        });
    }
  }
  handleXs = () => {
    this.props.history.push({ pathname: "./clxsTrEitder" });
  }
  setMapList = () => {
    if (!this.state.isLoaded) {
      return null;
    } else {
      return this.state.cllist.map((item, key) => (
        <Item multipleLine extra={item.crzlx} key={key} onClick={() => this.handleJump(item)}>
          {item.cph} <Brief>{item.sqr}</Brief>
          <Brief>{item.yxdqzt}</Brief>
        </Item>
      ))
    }
  }
  render() {
    return (
      <div style={{ marginTop: ".89rem" }}>
        {/* 导航栏#0F84D6 */}
        <NavBar
          mode="dark"
          icon={<Icon type="left" />}
          // rightContent={[
          //   <div onClick={this.handleXs}>为他人延期</div>
          // ]}
          onLeftClick={() => this.props.history.push({
            pathname: './main',
            search: `?isNone='none'`
        })}
        >车辆延期</NavBar>
        <div className="divmagin">
        <NoticeBar marqueeProps={{ loop: true, style: { padding: '0 7.5px' } }}>
                车辆延期申请提交后，预计三个工作日内审核完成
        </NoticeBar>
          {this.setMapList()}
        </div>
      </div>
    )
  }
}
